.completed,
.canceled,
.reported {
    border-radius: 0.8rem;
    font-size: $small-font-size;
    font-weight: 500;
    padding: 0.2rem 0.8rem;
}

.package-status-badge {
    .completed {
        background-color: $completed-bg;
        color: $completed;
    }

    .reported {
        background-color: $reported-bg;
        color: $reported;
    }

    .canceled {
        background-color: $canceled-bg;
        color: $canceled;
    }
}

.package-price,
.package-price-color {
    font-size: $base-font-size;
    font-weight: 600;

    .price-amount {
        color: $info-color;
        padding-right: 0.3rem;
    }
}

.package-price {
    .price-amount {
        padding-right: 0.3rem;
    }
}

.unassign-courier {
    align-items: center;
    justify-content: center;
    min-height: 25rem;
    min-width: 20vw;
    position: relative;
}

.invite-courier-modal-wrapper {
    .custom-modal-card {
        max-width: 54rem;

        .invite-courier-modal {
            align-items: flex-start;
            text-align: left;

            .invite-description {
                margin-bottom: 2.4rem;
            }
        }
    }
}

.description {
    &.delete-account {
        align-items: center;
        flex-direction: column;
        gap: 1.6rem;
    }
}

// reports chart last label on x axis not cut off

.reports-chart-wrapper {
    .apexcharts-canvas {
        svg {
            &.apexcharts-svg {
                overflow: visible;
            }
        }
    }
}


