.active-courier-list-item-actions {
    align-items: center;
    display: flex;
    gap: 0.8rem;

    .package-size-icon {
        font-size: $xxs-font-size;
        height: 2rem;
        width: 2rem;
    }
}

.map-container-main {
    position: relative;

    .leaflet-container {
        height: 100vh;
        min-width: 100%;
    }

    .refresh-position-button {
        position: absolute;
        right: 5rem;
        top: 5rem;
        z-index: 1000;
    }

    .custom-courier-marker {
        height: 5rem;
        position: relative;
        width: 5rem;

        .marker-icon-image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}
