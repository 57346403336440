.vehicle-status-badge {
    border-radius: 0.5rem;
    font-size: $small-font-size;
    font-weight: 600;
    padding: 0.5rem 1rem;

    &.active {
        background-color: $status-badge-active !important;
        color: $success;
    }

    &.inactive {
        background-color: $status-badge-inactive !important;
        color: $danger;
    }

    &.verification_pending {
        background-color: $status-badge-verification_pending !important;
        color: $warning;
    }
}

.vehicle-create-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0;

    .default-form {
        .form-control-wrapper {
            .label-container {
                .form-label {
                    color: $section-title-color;
                    font-size: $small-font-size;
                    font-weight: 400;
                }
            }

            &.add-verification-document-input {
                .uploader-control {
                    .uploader-wrapper {
                        .uploader-information {
                            padding: 1rem 0;
                        }

                        .form-input {
                            .rs-uploader-trigger {
                                .rs-uploader-trigger-btn {
                                    align-items: center;
                                    border: thin solid $lightest-text-color;
                                    border-radius: 0.4rem;
                                    color: $basic-text-color;
                                    display: flex;
                                    flex: 1;
                                    font-family: $inter-font-family;
                                    justify-content: space-between;
                                    padding: 2rem 0;

                                    .uploader-label {
                                        font-size: $base-font-size;
                                    }

                                    .file-upload-button {
                                        background-color: transparent;
                                        display: flex;
                                        font-size: $base-font-size;
                                        font-weight: 500;
                                        padding: 0.3rem 1rem 0.3rem 3rem;

                                        .file-upload-button-icon {
                                            background-image: url(../images/file-uploader-icon.svg);
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                            height: 2rem;
                                            width: 2rem;
                                        }
                                    }
                                }
                            }

                            .rs-uploader-file-items {
                                .rs-uploader-file-item {
                                    justify-content: stretch;
                                    padding: 0;

                                    .rs-uploader-file-item-icon-wrapper {
                                        display: none;
                                    }

                                    .rs-uploader-file-item-panel {
                                        display: flex;
                                        flex: 1;
                                        justify-content: stretch;
                                        margin: 0;

                                        .rs-uploader-file-item-content {
                                            display: flex;
                                            flex: 1;
                                            justify-content: stretch;

                                            .file-list-item {
                                                align-items: center;
                                                border-bottom: thin solid $lightest-text-color;
                                                display: flex;
                                                flex: 1;
                                                justify-content: space-between;

                                                .remove-file-button {
                                                    background-color: transparent;
                                                    color: $basic-text-color;
                                                    font-size: $base-font-size;
                                                    font-weight: 500;
                                                    padding: 1rem;
                                                }
                                            }

                                            .rs-uploader-file-item-size {
                                                display: none;
                                            }
                                        }
                                    }

                                    .rs-uploader-file-item-btn-remove {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    align-items: stretch;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
            }

            &.vehicle-package-size {
                align-items: stretch;

                .radio-control {
                    display: flex;
                    gap: 1.2rem;
                    justify-content: space-between;

                    .radio-wrapper {
                        border: thin solid $lightest-text-color;
                        border-radius: 1rem;
                        flex-direction: column;
                        gap: 0.5rem;
                        max-width: 9rem;
                        padding-bottom: 1.5rem;

                        &:has(input[type='radio']:checked) {
                            border: thin solid $black;

                            .radio-additional-description {
                                color: $black;
                            }
                        }

                        .radio-additional-description {
                            color: $lightest-text-color;
                            font-size: $small-font-size;
                            font-weight: 500;
                            text-align: center;
                        }

                        .radio-label {
                            align-items: center;
                            background-color: $white;
                            border-radius: 1rem;
                            box-shadow: 0 0 1.5rem $package-label-box-shadow;
                            color: $black-color;
                            display: flex;
                            font-weight: 500;
                            height: 2rem;
                            justify-content: center;
                            margin: 0;
                            width: 2rem;
                        }
                    }
                }
            }

            .react-datepicker-host {
                .react-datepicker-wrapper {
                    .react-datepicker__input-container {
                        .react-datepicker__aria-live {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    .new-vehicle-card {
        .MuiCardHeader-content {
            overflow-x: auto;

            .stepper-wrapper {
                overflow: auto;
            }

        }

        .package-item-container {
            flex-direction: column;
        }
    }

    .vehicle-host-section {
        padding: 2.6rem;

        .card-actions-button-wrapper {
            justify-content: space-between;

            .vehicle-nav-button {
                font-size: $small-font-size;
                padding: 0.6rem 1.2rem;
            }
        }
    }
}
