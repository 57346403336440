.settlements-settings-container {
    display: 'flex'; 
    flex-direction: 'column'; 
    gap: 1rem;

    .description {
        color: $subtitle-text-color;
        font-size: $base-font-size;
        margin-bottom: 2.4rem;
        margin-top: 0.8rem;
    }

    .config-button {
        padding: 0.8rem 2.2rem;
    }
}