.content-view {
    &.settlements {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        .settlements-boxes {
            .MuiGrid-item {
                display: flex;

                .box {
                    align-items: center;
                    display: flex;
                    flex: 1;
                    padding: 3.2rem 2.4rem;

                    .description {
                        color: $subtitle-text-color;
                        font-weight: 400;
                        margin-left: 1.6rem;
                        padding: 0;

                        .package-price-color {
                            font-family: $jakarta-font-family;
                            font-size: $x-font-size;
                            font-weight: 600;
                        }

                        .description-title {
                            font-size: $base-font-size;
                            line-height: 2.2rem;
                        }

                        .basic-income {
                            .package-price-color {
                                .price-amount {
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }

        .settlements-card-header {
            padding: 1.6rem 0;

            .MuiCardHeader-action {
                margin-right: 0;
            }
        }

        .settlements-card {
            margin-top: 0;
        }
    }
}
